import React from "react"
import Profile from "../components/idealline/Profile"
import FirebaseProvider from "../components/idealline/Firebase"
import { BrowserRouter } from "react-router-dom"
import { ClientOnly } from "../components/common"

const Idealline = (props) => (
    <ClientOnly>
        <BrowserRouter>
            <FirebaseProvider>
                <Profile />
            </FirebaseProvider>
        </BrowserRouter>
    </ClientOnly>
)
export default Idealline
